<template>
    <div class="selectTheNft-container">
        <div class="selectTheNft-title">Select the NFT type</div>
        <div class="select-upload-box">
            <div class="single-box" @click="goView('mint')">
                <div class="single-content">
                    <img src="../../assets/images/selectNft.png" class="selectNtf">
                    <div class="single-name">Single</div>
                    <div class="single-info">Mint a single moment</div>
                </div>
            </div>
            <div class="series-box" @click="goView('uploadMint')" :class="{'disabled':true}">
                <div class="single-content">
                    <!-- <img src="../../assets/images/selectNft1.png" class="selectNtf" v-if="$store.getters.walletInfo.chainId==1"> -->
                    <img src="../../assets/images/selectNft2.png" class="selectNtf">
                    <div class="single-name">Series</div>
                    <div class="single-info">Mint a series and crowd-funding</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        goView(view) {
            if (view === "uploadMint") {
                return;
            }
            this.$router.push({
                path: `/${view}`
            })
        },
    }
}
</script>
<style lang="scss" scoped src='../../assets/css/selectTheNft.scss'>
</style>
<style lang="scss">
.series-box {
    &.disabled {
        cursor: not-allowed;

        .single-name,
        .single-info {
            color: #B7BDC6;
        }
    }
}
</style>
<style lang="scss" scoped>
@media (max-width: 1125px) {

    .selectTheNft-container {
        margin: 3.68641rem 0 0;
        padding: 1.3312rem .768rem;


        .selectTheNft-title {
            margin: 0 0 .8192rem;

            // /*font-family: 'Poppins';*/
            font-style: normal;
            font-weight: 600;
            font-size: .9216rem;
            line-height: 1.5872rem;
            /* identical to box height, or 171% */

            text-align: left;

            color: #04111D;
        }

        .select-upload-box {
            margin: 0;
            flex-direction: column;

            .single-box,
            .series-box {
                width: 17.66405rem;
                height: 11.52003rem;
                border-radius: .8192rem;
                margin: 0 0 .8192rem;

                .single-content {
                    .selectNtf {
                        width: 3.07201rem;
                        height: 4.71041rem;
                        margin: 1.536rem auto 0;
                    }

                    .single-name {
                        font-style: normal;
                        font-weight: 600;
                        font-size: .8192rem;
                        line-height: 1.2288rem;
                        /* identical to box height */
                        text-align: center;
                        color: #040405;
                        margin-top: .8192rem;
                    }

                    .single-info {
                        // /*font-family: 'Poppins';*/
                        font-style: normal;
                        font-weight: 400;
                        font-size: .7168rem;
                        line-height: 1.0752rem;
                        /* identical to box height */

                        text-align: center;

                        color: #707A83;
                        margin-top: .6144rem;

                    }
                }


                &.disabled {
                    .single-content {
                        .single-name {
                            color: #B7BDC6;
                        }

                        .single-info {
                            color: #B7BDC6;
                        }
                    }
                }
            }

            .series-box {
                margin: 0;
            }
        }
    }
}
</style>